<template>
  <div class="popup">
    <div class="popup__container _container">
      <div class="popup__content">
        <slot name="text"></slot>
      </div>
      <div class="popup__btns">
        <input type="checkbox" class="checkbox" v-model="isChecked" />
        <button
          type="button"
          class="popup__btn apply-btn"
          @click="close"
          :disabled="!isChecked"
        >
          OK
        </button>
        <!-- <button type="button" class="popup__btn cancel-btn" @click="close">
          {{$t("cookie_warning.disagree_button")}}
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VPopup",
  data: () => ({
    isChecked: false
  }),
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.popup {
  position: fixed;
  bottom: 0;
  z-index: 11;
  width: 100%;
  background: rgb(7, 7, 7, 0.8);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;
    @include for-tablet-portrait-up {
      flex-direction: row;
      padding-top: 12px;
      padding-bottom: 12px;
      justify-content: space-between;
      align-items: center;
    }
    @include for-tablet-landscape-up {
      padding-top: 19px;
      padding-bottom: 19px;
    }
    @include for-desktop-up {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  .checkbox {
    outline: none;
    border: 1px solid $gray-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 5px;
    width: 26px;
    min-width: 26px;
    height: 26px;
    cursor: pointer;
    position: relative;
    &::before {
      content: url("../../assets/icons/checkbox.svg");
      position: absolute;
      right: 50%;
      bottom: 8px;
      transform: translate(50%, 50%);
      opacity: 0;
      transition: 0.1s ease-in;
    }
    &:checked::before {
      opacity: 1;
    }
  }
  &__btns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    @include for-tablet-portrait-up {
      margin-top: 0;
    }
    @include for-tablet-landscape-up {
    }
  }
  .checkbox + &__btn {
    margin-left: 10px;
    @include for-tablet-portrait-up {
      margin-left: 12px;
    }
    @include for-tablet-landscape-up {
      // margin-top: 0;
      margin-left: 18px;
    }
    @include for-desktop-up {
      margin-left: 20px;
    }
  }
  &__btn {
    font-family: "Manrope", sans-serif;
    text-decoration: none;
    @include P4();
    width: 182px;
    height: 42px;
    @include for-tablet-landscape-up {
      width: 200px;
      height: 46px;
      @include P3();
    }
  }
  .apply-btn {
    @include button();
  }
  .cancel-btn {
    @include cancel-button();
  }
  &__content {
    font-family: "Manrope", sans-serif;
    color: $white-color;
    text-align: center;
    @include P4();
    @include for-tablet-portrait-up {
      @include P4();
      width: 80%;
      text-align: left;
    }
    @include for-tablet-landscape-up {
      @include P3();
      width: 70%;
    }
  }
}
</style>
